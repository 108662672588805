import React from 'react';
import { AwardFeature } from '../Components/Feature/AwardFeature';
import { SpecialitiesFeature } from '../Components/Feature/SpecialitiesFeature';

export const Awards = () => {
    return (
        <>
            <SpecialitiesFeature
                header={'Awards'}
                para={'A Glimpse of awards we collected.'}
                bg={'bg-gallery'}
            />
            <AwardFeature />
        </>
    );
};
