import { IMAGES } from '../../Images';

export const TeamMessage = () => {
    return (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    our talented team of professionals
                </p>
                <p className="text-base text-gray-700 md:text-lg">
                    With over 15 years of medical experience
                </p>
            </div>
            <div className="grid gap-10 mx-auto lg:grid-cols-2 lg:max-w-screen-lg">
                <div className="grid sm:grid-cols-3">
                    <div className="relative w-full h-48 max-h-full rounded shadow sm:h-auto">
                        <img
                            className="absolute object-cover w-full h-full rounded"
                            // src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                            src={IMAGES.drAmar}
                            alt="Person"
                        />
                    </div>
                    <div className="flex flex-col justify-center text-justify mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
                        <p className="text-lg font-bold">Dr Amarnath Prasad</p>
                        <p className="mb-4 text-xs text-gray-800">
                            MBBS, MS(ENT)
                        </p>
                        <p className="mb-4 text-sm tracking-wide text-gray-800 text-justify">
                            Senior Consultant and Managing Director.
                            <br /> Former Senior Resident , AIIMS
                            <br />
                        </p>
                        <p className="flex text-xs text-justify">
                            Dr. Amarnath Prasad has acquired his MBBS at Burdwan
                            Medical College, Burdwan, West Bengal and MS from
                            The prestigious Ramakrishana Mission Seva
                            Pratishthan (RKMSP) Kolkata. He is also a life
                            member of Indian Medical Association (IMA) Dr.
                            Amaranth Prasad follows strict protocols to diagnose
                            the patient’s disease. Otology is his subspecialty
                            of choice. He is also well trained In Functional
                            Endoscopic Sinus Surgery(FESS) Voice Surgery and
                            Head Neck surgery. Dr.Amarnath Prasad has his credit
                            for large number of successful revision ear
                            surgeries and revision FESS surgeries.He has also
                            published several research papers.
                        </p>
                    </div>
                </div>
                <div className="grid sm:grid-cols-3">
                    <div className="relative w-full h-48 max-h-full rounded shadow sm:h-auto">
                        <img
                            className="absolute object-cover w-full h-full rounded"
                            // src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                            src={IMAGES.drArchana}
                            alt="Person"
                        />
                    </div>
                    <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
                        <p className="text-lg font-bold">Dr Archana Singh</p>
                        <p className="mb-4 text-xs text-gray-800">
                            MBBS, MS(Obs & Gynae)
                        </p>
                        <p className="mb-4 text-sm tracking-wide text-gray-800 text-justify">
                            Founder and Managing Director .
                            <br />
                        </p>
                        <p className="mb-4 text-xs text-gray-800">
                            Dr. Archana Singh passed her MBBS from BMC, Burdwan
                            and did her MS from NRS Medical College, Kolkata.
                            She is a well known Gynecologist in Bihar.
                            Infertility Care is her choice. She is trained in
                            Laproscopic Surgery .She has done a large number of
                            Caesarean Section and Hysterectomies. She is a life
                            member of IMA and FOGSF.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
