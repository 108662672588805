import { IMAGES } from '../../Images';
export const AboutFeature = () => {
    const aboutData = [
        {
            head: 'Patient Care',
            subHead:
                'One of the foundations on which the Aakriti Hospital assemble was fabricated was quiet centricity. It is a center esteem that we as a therapeutic association comprehend and trust in from the plain beginnings. It is likewise the plain reason regardless we hold the banner of the ‘Hippocratic Oath’ high, and why we accept energetically that the therapeutic brotherhood has a commitment to our kindred people and our patients',
        },
        {
            head: 'Experienced Doctors',
            subHead:
                'At Aakriti Hospital,, we value having the best. We have the best specialists in the field of ‘Ear, Nose and Throat’ illnesses and medicines working or counseling with us. Other than the unmatched and huge experience our specialists convey to the table, some have been at the front line of headways in their particular therapeutic specialties – with over a 1000 medical procedures shockingly or potentially co-writing books.',
        },
        {
            head: 'Advance Research',
            subHead:
                'Research is the establishment of every last restorative headway being made over the present reality. What’s more, our pledge to logical research is faithful in its force and approach. We additionally trust it is our duty to extend the outskirts of logical comprehension, make orbital moves and bring the most substantial medicinal research-achievements to the advantage of our patients.',
        },
        {
            head: 'High-Tech OT Setup',
            subHead: 'At Aakriti Hospital, we have ultra hightech OT setup.',
        },
    ];
    return (
        <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="absolute inset-0 ">
                <div className="p-6 absolute inset-y-0 z-0 w-full h-full bg-gray-100 lg:w-3/4" />
            </div>
            <div className="relative">
                <div className="grid gap-12 row-gap-8 lg:grid-cols-2 p-5">
                    <div className="grid gap-12 row-gap-5 md:grid-cols-2">
                        {aboutData.map((data) => (
                            <div>
                                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-teal-accent-400">
                                    <svg
                                        className="w-8 h-8 text-teal-900"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </div>
                                <h6 className="mb-2 font-semibold leading-5">
                                    {data.head}
                                </h6>
                                <p className="text-sm text-gray-900">
                                    {data.subHead}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div>
                        <img
                            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                            src={IMAGES.entChamber}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
