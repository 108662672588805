import React from 'react';
import { IMAGES } from '../../Images';

export const AwardFeature = () => {
    const certImages = [
        { image: IMAGES.cert1 },
        { image: IMAGES.cert2 },
        { image: IMAGES.cert3 },
        { image: IMAGES.cert4 },
        { image: IMAGES.cert5 },
        { image: IMAGES.cert6 },
        { image: IMAGES.cert7 },
        { image: IMAGES.cert8 },
        { image: IMAGES.cert9 },
    ];
    return (
        <>
            <section className="overflow-hidden text-gray-700 ">
                <div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
                    <div className="flex flex-wrap -m-1 md:-m-2">
                        {certImages.map((cert) => (
                            <div className="flex flex-wrap w-1/3">
                                <div className="w-full p-1 md:p-2">
                                    <img
                                        alt="gallery"
                                        className="block object-fill object-center w-full h-full rounded-lg"
                                        src={cert.image}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};
