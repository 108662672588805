import { IMAGES } from '../../Images';

const Content = () => {
    return (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <div>
                    <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                        Brand
                    </p>
                </div>
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                    <span className="relative inline-block">
                        <svg
                            viewBox="0 0 52 24"
                            fill="currentColor"
                            className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                        >
                            <defs>
                                <pattern
                                    id="2feffae2-9edf-414e-ab8c-f0e6396a0fc1"
                                    x="0"
                                    y="0"
                                    width=".135"
                                    height=".30"
                                >
                                    <circle cx="1" cy="1" r=".7" />
                                </pattern>
                            </defs>
                            <rect
                                fill="url(#2feffae2-9edf-414e-ab8c-f0e6396a0fc1)"
                                width="52"
                                height="24"
                            />
                        </svg>
                        <span className="relative">Why</span>
                    </span>{' '}
                    we are the best ?
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                    With 15 years of experience we provide best results in Ear,
                    Nose & Throat Surgery along with high quality care to
                    Maternity problems.
                </p>
            </div>
            <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
                <div className="grid grid-cols-2 gap-5">
                    <img
                        className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
                        src={IMAGES.otDoing}
                        alt=""
                    />
                    <img
                        className="object-cover w-full h-48 rounded shadow-lg"
                        src={IMAGES.ot}
                        alt=""
                    />
                    <img
                        className="object-cover w-full h-48 rounded shadow-lg"
                        src={IMAGES.duo}
                        alt=""
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <div className="pb-4 mb-4 border-b">
                        <h6 className="mb-2 font-semibold leading-5">
                            Expert Team Works.
                        </h6>
                        <p className="text-sm text-gray-900">
                            Aakriti Hospital provides Expert Team Work in
                            Ear,Nose and Throat Surgery as well as Maternity
                            Care
                        </p>
                    </div>
                    <div className="pb-4 mb-4 border-b">
                        <h6 className="mb-2 font-semibold leading-5">
                            Modern Operation Theater.
                        </h6>
                        <p className="text-sm text-gray-900">
                            Aakriti Hospital has Ultra Modern Operation Theater
                            with all Emergency Facilities
                        </p>
                    </div>
                    <div className="pb-4 mb-4 border-b">
                        <h6 className="mb-2 font-semibold leading-5">
                            Specify Clinic for Vertigo,Sleep & Voice Disorders.
                        </h6>
                        <p className="text-sm text-gray-900">
                            The Hospital has specific clinic for Vertigo, Sleep
                            and Voice Disorders for dedicated Patients.
                        </p>
                    </div>
                    <div>
                        <h6 className="mb-2 font-semibold leading-5">
                            Modern Operation Theater.
                        </h6>
                        <p className="text-sm text-gray-900">
                            Aakriti Hospital has Ultra Modern Operation Theater
                            with all Emergency Facilities
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;
