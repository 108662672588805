import Content from '../Components/Feature/Content';
import Feature from '../Components/Feature/Feature';
// import TeamFeature from "../Components/Feature/TeamFeature";
import { TeamMessage } from '../Components/Feature/TeamMessage';
import Hero from '../Components/Hero/Hero';
const Home = () => {
    return (
        <>
            <Hero />
            <Feature />
            <Content />
            <TeamMessage />
        </>
    );
};
export default Home;
