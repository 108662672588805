import React from 'react';
import earImage from '../../Images/ear1.png';
import noseImage from '../../Images/nose1.png';
import throatImage from '../../Images/throat1.png';
import pregImage from '../../Images/pregnant.png';

export const TreatmentFeature = () => {
    const earArray = [
        'Tympanoplasty',
        'Mastoidectomy',
        ' Stapes Surgery',
        'Grommet Insertion',
        'Pinna and Ear Canal Reconstructive Surgery',
        'CSF Otorrhoea Surgery',
        'Facial Nerve Surgery',
        'Skull Base Surgery',
    ];

    const noseArray = [
        'SMR',
        'Septoplasty',
        'FESS(Functional Endoscopic Sinus Surgery)',
        'Rhinoplasty',
    ];

    const throatArray = [
        'Head Neck Surgery including Thyroid & Parotid Gland Surgery',
        'MLS Surgery',
        'Tonsil and Adenoid Surgery',
    ];

    const pregArray = [
        'Lower Segment Caesarean Section(LSCS)',
        'Ovarian Surgery',
        'MTP',
        'Intra Uterine Insemination for Infertility',

        'Hystereactomy',
        'Myomectomy',
        'Normal Delivery',
    ];
    return (
        <div className="px-4  py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
                <div className="lg:w-1/2">
                    <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                        When you care it shows
                    </h2>
                </div>
                <div className="lg:w-1/2">
                    <p className="text-base text-gray-700 md:text-lg">
                        With almost 150 successful surgeries a month, we are
                        pioneers in the below mentioned surgeries and
                        treatments. Do reach out at our phone numbers to know
                        more. Reach out to schedule an appointment
                    </p>
                </div>
            </div>
            <div className="grid gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-4">
                <div>
                    <div className="flex items-center justify-center w-20 h-w-20 mb-4 rounded-full bg-indigo-50">
                        <img src={earImage} alt="" />
                    </div>
                    <h6 className="mb-2 font-semibold leading-5">ENT</h6>
                    <p className="mb-3 text-sm text-gray-900">
                        With Great experience and results we have done surgeries
                        like
                    </p>
                    <ul className="mb-4 -ml-1 space-y-2">
                        {earArray.map((ear) => {
                            return (
                                <li className="flex items-start">
                                    <span className="mr-1">
                                        <svg
                                            className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                            stroke="currentColor"
                                            viewBox="0 0 52 52"
                                        >
                                            <polygon
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                fill="none"
                                                points="29 13 14 29 25 29 23 39 38 23 27 23"
                                            />
                                        </svg>
                                    </span>
                                    {ear}
                                </li>
                            );
                        })}
                        <li className="flex items-start">
                            <span className="mr-1">
                                <svg
                                    className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                    stroke="currentColor"
                                    viewBox="0 0 52 52"
                                >
                                    <polygon
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fill="none"
                                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                                    />
                                </svg>
                            </span>
                            And More ....
                        </li>
                    </ul>
                </div>
                <div>
                    <div className="flex items-center justify-center w-20 h-20 mb-4 rounded-full bg-indigo-50">
                        <img src={noseImage} alt="" />
                    </div>
                    <h6 className="mb-2 font-semibold leading-5">Nose</h6>
                    <p className="mb-3 text-sm text-gray-900">
                        With Great experience and results we have done surgeries
                        like
                    </p>
                    <ul className="mb-4 -ml-1 space-y-2">
                        {noseArray.map((nose) => (
                            <li className="flex items-start">
                                <span className="mr-1">
                                    <svg
                                        className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </span>
                                {nose}
                            </li>
                        ))}

                        <li className="flex items-start">
                            <span className="mr-1">
                                <svg
                                    className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                    stroke="currentColor"
                                    viewBox="0 0 52 52"
                                >
                                    <polygon
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fill="none"
                                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                                    />
                                </svg>
                            </span>
                            And More...
                        </li>
                    </ul>
                </div>
                <div>
                    <div className="flex items-center justify-center w-20 h-20 mb-4 rounded-full bg-indigo-50">
                        <img src={throatImage} alt="" />
                    </div>
                    <h6 className="mb-2 font-semibold leading-5">Throat</h6>
                    <p className="mb-3 text-sm text-gray-900">
                        With Great experience and results we have done surgeries
                        like
                    </p>
                    <ul className="mb-4 -ml-1 space-y-2">
                        {throatArray.map((throat) => (
                            <li className="flex items-start">
                                <span className="mr-1">
                                    <svg
                                        className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </span>
                                {throat}
                            </li>
                        ))}
                        <li className="flex items-start">
                            <span className="mr-1">
                                <svg
                                    className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                    stroke="currentColor"
                                    viewBox="0 0 52 52"
                                >
                                    <polygon
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fill="none"
                                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                                    />
                                </svg>
                            </span>
                            And Many More....
                        </li>
                    </ul>
                </div>
                <div>
                    <div className="flex items-center justify-center w-20 h-20 mb-4 rounded-full bg-indigo-50">
                        <img src={pregImage} alt="" />
                    </div>
                    <h6 className="mb-2 font-semibold leading-5">Maternity</h6>
                    <p className="mb-3 text-sm text-gray-900">
                        With Great experience and results we have done surgeries
                        like
                    </p>
                    <ul className="mb-4 -ml-1 space-y-2">
                        {pregArray.map((preg) => (
                            <li className="flex items-start">
                                <span className="mr-1">
                                    <svg
                                        className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </span>
                                {preg}
                            </li>
                        ))}
                        <li className="flex items-start">
                            <span className="mr-1">
                                <svg
                                    className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                    stroke="currentColor"
                                    viewBox="0 0 52 52"
                                >
                                    <polygon
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fill="none"
                                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                                    />
                                </svg>
                            </span>
                            And Many More....
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
