import React from 'react';
import { ContactFeature } from '../Components/Feature/ContactFeature';

export const ContactUs = () => {
    return (
        <>
            <ContactFeature />
        </>
    );
};
