export const SpecialitiesFeature = ({ header, para, bg }) => {
    return (
        <div>
            <div
                className={`flex w-full sm:h-96 ${bg} bg-no-repeat bg-center bg-cover `}
                // className={`flex w-full h-56 sm:h-96 bg-gallery bg-no-repeat bg-center bg-cover `}
            >
                <div
                    className="flex w-full"
                    style={{ background: 'rgba(0, 0, 0, 0.4)' }}
                >
                    <div className="text-center m-auto z-50 text-white w-full p-10">
                        <div className="text-3xl mb-2"> {header} </div>
                        <div className="h-px mb-2 bg-white"></div>
                        <span>{para}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
