import React from 'react';
import { SpecialitiesFeature } from '../Components/Feature/SpecialitiesFeature';
import { SpecialTypes } from '../Components/Feature/SpecialTypes';
import { TreatmentFeature } from '../Components/Feature/TreatmentFeature';

export const Specialities = () => {
    return (
        <>
            <SpecialitiesFeature
                header={'SPECIALTIES, TREATMENTS & SURGERIES'}
                para={
                    'Super Special Care - As a healthcare institution, our singular focus is to alleviate the pain and suffering of our patients not just through world-class medical research, but human touch as well.'
                }
                bg={'bg-hero'}
            />
            <TreatmentFeature />
            <SpecialTypes />
        </>
    );
};
