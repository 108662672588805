import React from 'react';
import { GalleryFeature } from '../Components/Feature/GalleryFeature';
import { SpecialitiesFeature } from '../Components/Feature/SpecialitiesFeature';

export const Gallery = () => {
    return (
        <>
            <SpecialitiesFeature
                header={'Gallery'}
                para={'A glimpse into our World'}
                bg={'bg-gallery'}
            />
            <GalleryFeature />
        </>
    );
};
