import { IMAGES } from '../../Images';

export const SpecialTypes = () => {
    const hosSpec = [
        {
            img: IMAGES.otoimage,
            head: 'Otosclerosis and stapes surgery',
            para: 'Otosclerosis is a condition causing hearing loss due to abnormal bone growth in the middle ear. At Aakriti Hospital, we specialize in stapedectomy, a surgical procedure to improve hearing in otosclerosis patients. Our experienced team has performed over 15,000 such operations, with a success rate exceeding 98%. We are proud to be one of the leading centers in India for this procedure, conducting approximately 400 stapedotomy surgeries annually. Our expertise ensures optimal outcomes and quick recovery for our patients.',
        },
        {
            img: IMAGES.earDisImage,
            head: 'Ear discharge and tympanomastoid surgery',
            para: 'Mastoidectomy and tympanoplasty are surgical procedures of the middle ear performed in order to close a hole in the ear drum, remove the infection in the middle ear and mastoid bone and to improve hearing. The Aakriti Hospital is the leading hospital in the surgical management of this condition called chronic suppurative otitis media. At our Institute, we excel in the treatment of this condition and successful results approach 100%! Procedures at our hospital also have a very small convalescing time allowing the patient to return home quickly after a successful operation.',
        },
        {
            img: IMAGES.cochlearImage,
            head: 'Cochlear implant surgery',
            para: 'The Aakriti Hospital runs the largest cochlear implant program in India. An average of 250 implantations is done every year. More than 2000 implants have been performed at the Aakriti Hospital & Research Institute. The cochlear implant is a safe, medical device that replicates the function of the cochlea when it is damaged. In essence what it does is pick up sound with the aid of the microphones in the processor, which then sends it to the implant, which stimulates the auditory nerve directly sending signals to the brain.',
        },
        {
            img: IMAGES.noseSinus,
            head: 'Nose and sinus surgery',
            para: 'Aakriti Hospital specializes in advanced ENT procedures, including functional endoscopic sinus surgery (FESS). Our experienced team utilizes minimally invasive techniques to treat various sinus conditions, ensuring optimal sinus ventilation and function. With state-of-the-art technology and a patient-centered approach, we provide comprehensive care for nasal and sinus ailments. Our expertise in FESS, along with other innovative treatments, allows us to achieve excellent outcomes for our patients, improving their quality of life and respiratory health.',
        },
    ];
    return (
        <div className="bg-gray-100">
            <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="absolute inset-x-0 top-0 items-center justify-center hidden overflow-hidden md:flex md:inset-y-0">
                    <svg
                        viewBox="0 0 88 88"
                        className="w-full max-w-screen-xl text-indigo-100"
                        aria-hidden="true"
                    >
                        <circle fill="currentColor" cx="44" cy="44" r="15.5" />
                        <circle
                            fillOpacity="0.2"
                            fill="currentColor"
                            cx="44"
                            cy="44"
                            r="44"
                        />
                        <circle
                            fillOpacity="0.2"
                            fill="currentColor"
                            cx="44"
                            cy="44"
                            r="37.5"
                        />
                        <circle
                            fillOpacity="0.3"
                            fill="currentColor"
                            cx="44"
                            cy="44"
                            r="29.5"
                        />
                        <circle
                            fillOpacity="0.3"
                            fill="currentColor"
                            cx="44"
                            cy="44"
                            r="22.5"
                        />
                    </svg>
                </div>

                <div className="relative grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {hosSpec.map((spec, index) => (
                        <div
                            key={spec.head}
                            className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl"
                        >
                            <div className="p-5">
                                <div className="flex items-center justify-center mb-4 rounded-full bg-indigo-50">
                                    <img src={spec.img} alt={spec.head} />
                                </div>
                                <p className="mb-2 font-bold"> {spec.head} </p>
                                <p className="text-sm leading-5 text-gray-900">
                                    {spec.para}
                                </p>
                            </div>
                            <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
