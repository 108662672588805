import Typewriter from 'typewriter-effect';
import Building from '../../Images/building.jpg';
const Hero = () => {
    return (
        <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
            <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
                <svg
                    className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
                    viewBox="0 0 100 100"
                    fill="currentColor"
                    preserveAspectRatio="none slice"
                >
                    <path d="M50 0H100L50 100H0L50 0Z" />
                </svg>
                <img
                    className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
                    src={Building}
                    alt=""
                />
            </div>
            <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
                <div className="mb-16 w-96 max-h-44 lg:my-40 lg:max-w-lg lg:pr-5">
                    <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                        This is
                    </p>
                    <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                        Aakriti Hospital
                        <br />
                        <br className="hidden md:block" />
                        <span className="text-2xl">Reshape Your </span>
                        <span className="inline-block text-purple-accent-400">
                            Health
                        </span>
                    </h2>
                    <p className="pr-8 mb-5 text-base text-gray-700 md:text-lg">
                        <Typewriter
                            options={{
                                autoStart: true,
                                loop: true,
                            }}
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString('Welcome to Aakriti Hospital!')
                                    // .callFunction(() => {
                                    //   console.log("String typed out!");
                                    // })
                                    .pauseFor(100)
                                    .deleteAll()
                                    .typeString(
                                        '<b>Empanelled under <span style="color: #27ae60;">Ayushman Bharat PM-JAY</span> (ENT)</b>'
                                    )
                                    .deleteAll()
                                    // .callFunction(() => {
                                    //   console.log("Reshape your Health");
                                    // })
                                    .pauseFor(100)
                                    // .typeString(
                                    //     `<b> Specialized in <span style="color: #27ae60;"> Laparoscopic Surgery </span></b>`
                                    // )
                                    // .pauseFor(100)
                                    // .deleteChars(21)
                                    .typeString(
                                        '<b> Specialized in <span style="color: #27ae60;">Head Neck Surgery</span></b>'
                                    )
                                    .pauseFor(200)
                                    .deleteChars(17)
                                    .typeString(
                                        `<b><span style="color: #27ae60;">Ear, Nose and Throat Surgery</span></b>`
                                    )
                                    // .typeString(
                                    //   '<strong>only <span style="color: #27ae60;">5kb</span> Gzipped!</strong>'
                                    // )
                                    .pauseFor(1000)
                                    .start();
                            }}
                        />
                    </p>
                    {/* <div className="flex items-center">
            <button
              href="/"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-gray-700 transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              Get started
            </button>
            <button
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              Learn more
            </button>
          </div> */}
                </div>
            </div>
        </div>
    );
};

export default Hero;
