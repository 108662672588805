import React from 'react';
import { IMAGES } from '../../Images';

export const GalleryFeature = () => {
    const gal = [
        { image: IMAGES.entChamber },
        { image: IMAGES.doctorInAction },
        { image: IMAGES.vaccine },
        { image: IMAGES.reception },
        { image: IMAGES.lift },
        { image: IMAGES.ptWatch },
        { image: IMAGES.otBack },
        { image: IMAGES.camp },
        { image: IMAGES.duo },
    ];
    return (
        <section className="overflow-hidden text-gray-700 ">
            <div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
                <div className="flex flex-wrap -m-1 md:-m-2">
                    {gal.map((galImage) => (
                        <div className="flex flex-wrap w-1/3">
                            <div className="w-full p-1 md:p-2">
                                <img
                                    alt="gallery"
                                    style={{
                                        height: '300px',
                                    }}
                                    className="block object-cover object-center w-full h-full rounded-lg"
                                    src={galImage.image}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
