import React from 'react';
import { AppointmentFeature } from './AppointmentFeature';
import { SpecialitiesFeature } from './SpecialitiesFeature';

export const ContactFeature = () => {
    return (
        <>
            <SpecialitiesFeature
                header={'AVAILABLE ALWAYS'}
                para={
                    'Present in the heart of bihar we are always nearby reach out to us anytime.'
                }
                bg={'bg-hospital'}
            />
            <AppointmentFeature />
        </>
    );
};
