import earImage from '../../Images/ear.png';
import noseImage from '../../Images/nose.jpg';
import neckImage from '../../Images/neck.jpg';
import maternityImage from '../../Images/maternity.png';

const Feature = () => {
    return (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <div>
                    <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                        Brand
                    </p>
                </div>
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                    <span className="relative inline-block">
                        <svg
                            viewBox="0 0 52 24"
                            fill="currentColor"
                            className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                        >
                            <defs>
                                <pattern
                                    id="27df4f81-c854-45de-942a-fe90f7a300f9"
                                    x="0"
                                    y="0"
                                    width=".135"
                                    height=".30"
                                >
                                    <circle cx="1" cy="1" r=".7" />
                                </pattern>
                            </defs>
                            <rect
                                fill="url(#27df4f81-c854-45de-942a-fe90f7a300f9)"
                                width="52"
                                height="24"
                            />
                        </svg>
                        <span className="relative">The</span>
                    </span>{' '}
                    Vision and Misson of
                    <span style={{ color: '#27ae60' }}> Aakriti Hospital </span>
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                    "Is to provide quality patient care with unrelenting
                    attension to clinical excellence, patient saftey and an
                    unparalleled passion and commitment to ensure the very best
                    ENT & Maternity healthcare for those we serve.”
                </p>
            </div>
            <div className="grid max-w-screen-lg gap-8 row-gap-10 mx-auto lg:grid-cols-2">
                <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
                    <div className="mr-4">
                        <div className="flex items-center justify-center w-36 h-36 mb-4 rounded-full bg-indigo-50">
                            <img
                                src={earImage}
                                alt="earImage"
                                className="w-24 h-24"
                            />
                        </div>
                    </div>
                    <div>
                        <h6 className="mb-3 text-xl font-bold leading-5">
                            Ear
                        </h6>
                        <p className="mb-3 text-sm text-gray-900">
                            Sounds are all over the place, and you have two cool
                            parts on your body that let you hear them every one
                            of: your ears! Your ears are accountable for
                            gathering sounds, preparing them, and sending sound
                            signs to your mind. What’s more, that is not all —
                            your ears likewise enable you to keep your parity.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
                    <div className="mr-4">
                        <div className="flex items-center justify-center w-36 h-36 mb-4 rounded-full bg-indigo-50">
                            <img
                                src={noseImage}
                                alt="earImage"
                                className="w-24 h-24"
                            />
                        </div>
                    </div>
                    <div>
                        <h6 className="mb-3 text-xl font-bold leading-5">
                            Nose
                        </h6>
                        <p className="mb-3 text-sm text-gray-900">
                            The smell of incense and blooms at your place of
                            love or that scrumptious home cooked nourishment is
                            an ordeal like no other. The nose is the organ of
                            smell and a principle way for air into and out of
                            the lungs. The nose warms, dampens, and cleans air
                            before it enters the lungs.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
                    <div className="mr-4">
                        <div className="flex items-center justify-center w-36 h-36 mb-4 rounded-full bg-indigo-50">
                            <img
                                src={neckImage}
                                alt="earImage"
                                className="w-24 h-24"
                            />
                        </div>
                    </div>
                    <div>
                        <h6 className="mb-3 text-xl font-bold leading-5">
                            Neck
                        </h6>
                        <p className="mb-3 text-sm text-gray-900">
                            A critical way between the outer condition and your
                            inward body, the throat is situated behind the
                            mouth, beneath the nasal depression, or more the
                            throat and windpipe. It conveys nourishment to the
                            throat and air to the lungs.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
                    <div className="mr-4">
                        <div className="flex items-center justify-center w-36 h-36 mb-4 rounded-full bg-indigo-50">
                            <img
                                src={maternityImage}
                                alt="earImage"
                                className="w-24 h-24"
                            />
                        </div>
                    </div>
                    <div>
                        <h6 className="mb-3 text-xl font-bold leading-5">
                            Maternity
                        </h6>
                        <p className="mb-3 text-sm text-gray-900">
                            A maternity healing facility represents considerable
                            authority in thinking about ladies amid pregnancy
                            and labor. It likewise gives care to babies, and may
                            go about as a middle for clinical preparing in
                            maternity care and obstetrics.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feature;
