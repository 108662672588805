import './App.css';
import Home from './Pages/Home';
import { Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import About from './Pages/About';
import { ContactUs } from './Pages/ContactUs';
// import { Footer } from './Components/Footer/Footer';
import { Specialities } from './Pages/Specialities';
import { Gallery } from './Pages/Gallery';
import { Awards } from './Pages/Awards';
import { FooterNew } from './Components/Footer/FooterFix';

function App() {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<ContactUs />} />
                <Route path="specialities" element={<Specialities />} />
                <Route path="gallery" element={<Gallery />} />
                <Route path="awards" element={<Awards />} />
            </Routes>
            <FooterNew />
            {/* <Footer /> */}
        </>
    );
}

export default App;
