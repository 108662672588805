import { Link } from 'react-router-dom';
import { IMAGES } from '../../Images';
import { navLinks } from '../../Util/navUtils';

export const FooterNew = () => {
    return (
        <>
            <footer className="p-4 bg-white sm:p-6 dark:bg-gray-800">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <img
                            src={IMAGES.logo}
                            className="mr-3 h-20"
                            alt="FlowBite Logo"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                                Resources
                            </h2>
                            <ul className="text-gray-600 dark:text-gray-400">
                                {navLinks.map((link) => (
                                    <>
                                        <li key={link.to} className="mb-4">
                                            <Link
                                                to={link.to}
                                                className="hover:underline"
                                            >
                                                {link.name}
                                            </Link>
                                        </li>
                                    </>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};
