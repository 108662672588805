import React from 'react';
import { AboutFeature } from '../Components/Feature/AboutFeature';
import { SpecialitiesFeature } from '../Components/Feature/SpecialitiesFeature';
import { TeamMessage } from '../Components/Feature/TeamMessage';

const About = () => {
    return (
        <>
            <SpecialitiesFeature
                bg={'bg-consultant'}
                header={'About'}
                para={
                    "Located in Patna,Near Shanichara Mandir, Kumhrar Sandalpur Road. Bihar's best ENT & Maternity Hospital with highest quality treatments."
                }
            />
            <AboutFeature />
            <TeamMessage />
            {/* <MileStone /> */}
        </>
    );
};

export default About;
