import { useState } from 'react';
import { AiFillFacebook, AiFillYoutube, AiOutlineMail } from 'react-icons/ai';
import { BiPhoneCall } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import logo from '../../Images/logo.jpeg';
import { navLinks } from '../../Util/navUtils';

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);
    function toogleMenu() {
        setOpenMenu(!openMenu);
    }

    return (
        <>
            <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded w-full ">
                <div className="hidden md:flex justify-end  items-center">
                    <div className="flex flex-col  md:flex-row  justify-end  items-center">
                        <a
                            className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                            href="tel:+916124031132"
                        >
                            <BiPhoneCall className="mr-2" />
                            6124031132
                        </a>
                        <a
                            className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                            href="tel:7070996104"
                        >
                            <BiPhoneCall className="mr-2" />
                            7070996104
                        </a>
                        <a
                            className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                            href="tel:7070996105"
                        >
                            <BiPhoneCall className="mr-2" />
                            7070996105
                        </a>
                        <a
                            className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                            href="tel:7070996106"
                        >
                            <BiPhoneCall className="mr-2" />
                            7070996106
                        </a>
                        <a
                            className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                            href={
                                'https://mail.google.com/mail/?view=cm&fs=1&to=' +
                                'aakritihospital@gmail.com'
                            }
                        >
                            <AiOutlineMail className="mr-2" />
                            aakritihospital@gmail.com
                        </a>
                    </div>
                    <a
                        className="flex items-center ml-4 p-1"
                        href="https://www.youtube.com/channel/UCdjuD8OCMfGR8pFohX5rtrQ/videos"
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        <AiFillYoutube
                            size={'2em'}
                            className="mr-2 text-red-700"
                        />
                    </a>
                    <a
                        className="flex items-center ml-2 p-1"
                        href="https://www.facebook.com/Aakritihospital"
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        <AiFillFacebook
                            size={'1.7em'}
                            className="mr-2 rounded-full text-blue-500"
                        />
                    </a>
                </div>
                <div className="container flex flex-wrap justify-between items-center mx-auto">
                    <div className="flex flex-col md:items-center">
                        <Link to={'/'} href="#" className="flex">
                            <img src={logo} alt="logo" />
                        </Link>
                        <div className="text-center text-[#e2343a] max-w-[250px]">
                            Empanelled under Ayushman Bharat PM-JAY(ENT)
                        </div>
                    </div>
                    <button
                        onClick={toogleMenu}
                        data-collapse-toggle="mobile-menu"
                        type="button"
                        className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="mobile-menu-2"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                        <svg
                            className="hidden w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    <div
                        className={`${
                            openMenu ? '' : 'hidden'
                        }  w-full md:block md:w-auto `}
                        id="mobile-menu"
                    >
                        <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                            {navLinks.map((link, ind) => (
                                <li onClick={toogleMenu} key={link.name}>
                                    <Link
                                        to={link.to}
                                        className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                                        aria-current="page"
                                    >
                                        {link.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <div className="md:hidden w-full mx-auto flex justify-center bg-gray-100 items-center ">
                            <div className=" flex flex-col items-center">
                                <div className="grid place-items-center justify-center items-center grid-cols-2 ">
                                    <a
                                        className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                                        href="tel:+916124031132"
                                    >
                                        <BiPhoneCall className="mr-2" />
                                        6124031132
                                    </a>
                                    <a
                                        className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                                        href="tel:7070996104"
                                    >
                                        <BiPhoneCall className="mr-2" />
                                        7070996104
                                    </a>
                                    <a
                                        className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                                        href="tel:7070996105"
                                    >
                                        <BiPhoneCall className="mr-2" />
                                        7070996105
                                    </a>
                                    <a
                                        className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                                        href="tel:7070996106"
                                    >
                                        <BiPhoneCall className="mr-2" />
                                        7070996106
                                    </a>
                                </div>
                                <a
                                    className="flex items-center ml-4 p-1 hover:bg-teal-500 rounded-full hover:text-white transition-colors duration-300"
                                    href={
                                        'https://mail.google.com/mail/?view=cm&fs=1&to=' +
                                        'aakritihospital@gmail.com'
                                    }
                                >
                                    <AiOutlineMail className="mr-2" />
                                    aakritihospital@gmail.com
                                </a>
                            </div>

                            <a
                                className="flex items-center ml-4 p-1"
                                href="https://www.youtube.com/channel/UCdjuD8OCMfGR8pFohX5rtrQ/videos"
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                <AiFillYoutube
                                    size={'2em'}
                                    className="mr-2 text-red-700"
                                />
                            </a>
                            <a
                                className="flex items-center p-1"
                                href="https://www.facebook.com/Aakritihospital"
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                <AiFillFacebook
                                    size={'1.7em'}
                                    className="mr-2 rounded-full text-blue-500"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
