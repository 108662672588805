export const IMAGES = {
    otoimage: require('./otology.jpg'),
    logo: require('./logo.jpeg'),
    cochlearImage: require('./cochlear-implant.jpg'),
    earDisImage: require('./ear-dis.jpg'),
    laryngologyImage: require('./laryngology.jpg'),
    noseSinus: require('./nose-sinus.jpg'),
    snoringImage: require('./snoring.jpg'),
    tonsillImage: require('./tonsillectomy.jpg'),
    entChamber: require('./entChamber.jpg'),
    doctorInAction: require('./doctorInAction.jpg'),
    ptWatch: require('./ptWatch.jpg'),
    vaccine: require('./vaccine.jpg'),
    reception: require('./reception.jpg'),
    lift: require('./lift.jpg'),
    surgeryOt: require('./surgeryOt.jpg'),
    operationTheatre: require('./operationTheatre.jpeg'),
    doctors: require('./doctors.jpg'),
    cert1: require('./cert1.jpg'),
    cert2: require('./cert2.jpg'),
    cert3: require('./cert3.jpg'),
    cert4: require('./cert4.jpg'),
    cert5: require('./cert5.jpg'),
    cert6: require('./cert6.jpg'),
    cert7: require('./cert7.jpg'),
    cert8: require('./cert8.jpg'),
    cert9: require('./cert9.jpg'),
    otDoing: require('./otDoing.jpeg'),
    ot: require('./ot.jpeg'),
    duo: require('./duo.jpeg'),
    camp: require('./camp.jpeg'),
    drAmar: require('./drAmarnath.jpeg'),
    drArchana: require('./drArchana.jpeg'),
    otBack: require('./otBack.jpeg'),
    building: require('./building.jpg'),
};
